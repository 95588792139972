.work-section-container{
    scrollbar-width: none;
}


/* 
.work-companies-list-flex-iteam{
    display: flex;
    margin: 10px;
    padding: 5px;

} */

.company-name-button-list{
    margin: 10px;
}




/* carousel css */

/* .tab-divider-style{
    border-top: 1px solid var(--primary-blue);
    border-bottom: 1px solid var(--primary-blue);
} */

.work-flex-container{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.work-carousel-container{
    width: 100%;
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;

    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0px;
    z-index: 1;


}


.tab-carousel-wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    position: relative;
}

.tab-carousel-btn{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    overflow: visible;
    cursor: pointer;

    padding: 16px 12px;
    white-space: nowrap;
    border: 0px;

    color: white;
    font-weight: 600;
}


::-webkit-scrollbar{
    height: 0;
    width: 0;
}


.isHidden {
    display: none;
}

.isImageActive {
    transform: scale(1.4);
}

.isActive{
    /* transform: scale(1.1); */
    /* color: var(--primary-blue) */
}

.isDisabled { 
    color: #757b9e;

}

.isShowing{
    clip-path: polygon(12% 0%, 60% 0%, 81.15% 0%, 100% 0%, 100% 60%, 90% 100%, 60.06% 100%, 12% 100%, 0% 100%, 0% 0%);
    border: 2px solid var(--primary-blue);
    /* border-right: 0px; */
    font-size: 20px;
    border-right: 5px solid var(--primary-blue);
}

.company-name-short-card{
    z-index: 2;
    background: black;
    line-height: -15px;
    text-align: end;
    align-content: center;
    width: 200px;
    height: 100px;
}


.image-container img {
    transition:  0.5s ease;
    transform: scale(1);
    cursor: pointer;
}


/* New card section */




.work-section-flex-container{
    display: flex;
    /* gap: 35px; */
    flex-wrap: wrap;
    margin: 5px;

    max-width: 80vw;
    min-height: 40vh;
}

/* Nav buttons */

.company-title{
    color: #757b9e;
    text-transform: uppercase;
}

.work-nav-name-container{
    max-width: 25%;
}



.company-name-nav-btns{
    margin: 10px;
    padding: 5px;


    transition:  0.2s ease;
    transform: scale(1);
    cursor: pointer;

}

.company-name-outer-layer{
    clip-path: polygon(12% 0%, 60% 0%, 81.15% 0%, 100% 0%, 100% 60%, 90% 100%, 60.06% 100%, 12% 100%, 0% 100%, 0% 0%);
    background-color: var(--primary-blue);
    padding: 1px;
    border: 2px solid var(--primary-blue);


}

.company-name-inner-layer{
    clip-path: polygon(80% 0%, 85% 10%, 100% 10%, 100% 60%, 90% 100%, 30% 100%, 20% 90%, 0% 90%, 0% 0%);
    background-color: #101f3d;
    font-weight: 700;

    padding: 0.8rem;
    font-size: 18px;
}




/* Timeline */

.work-timeline{
    justify-content: center;
    margin: auto auto;
}



.rv-work-duty-tech-section{
    max-width: 100vw;
    
}

.tab-label-container{
    max-width: 90vw;
    margin: 10px auto;
}

/* Layout for card area */

.work-card-section{
    display: flex;
    flex-direction: row;        
    /* justify-content: center; */
}
.card-id-section{
    justify-content: start;

    margin: 25px;
    font-size: 40px;
}

.work-card-details{
    display: flex;
    flex-direction: column;
}






/* MEDIA QUERIES */
/* for DeskTops and Laptops Screen Viewport */
@media (min-width: 1401px) {
    .work-section-flex-container{
        flex-direction: row;
        /* justify-content: center; */
    }

    .work-nav-name-container{

    }

    .work-nav-name-container{
        display: flex;
        flex-direction: column;
        max-width: 30%;
    }
    
    .company-name-button-list{
        display: flex;
        flex-direction: column;
        min-width: max-content;
    }

    .company-name-button-list{
        margin-top: 0px ;
    }

    .company-name-inner-layer{
        padding: 0.8rem;
        font-size: 18px;
     }

    .work-timeline{
        margin: 20px auto;
    }


    .company-title{
        font-size: 14px;
        font-weight: bold;
        margin-top: 15px;
    }

    .rv-work-duty-tech-section{
        max-width: 90vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        
    }
    
    .techstack-container{
        margin: 5px 10px;
        max-width: 40vw;
    }

    .responsibility-container{
        margin: 5px 10px;
        max-width: 60vw;
    } 

     /* Layout for card area */
     /* Logo card */
    .work-company-logo-card{
        margin: 5px;
        padding: 5px;
        display: flex;
        /* justify-content: end; */
        max-width: 75%;

    }

     .work-card-section{
        display: flex;
        flex-direction: row;        
        justify-content: center;
    }
    .card-id-section{
        justify-content: start;
        align-self: center;
        margin-left: 10px;
        font-size: 100px;
        font-weight: bold;
        color: transparent;
        -webkit-text-stroke: 2px var(--white); /* Webkit/Blink browsers */
        text-stroke: 2px var(--white); /* Standard syntax */
        font-family: 'Orbitron', sans-serif;
    }

    .work-card-details{
        display: flex;
        flex-direction: column;
    }

    .work-company-logo-card{
        justify-content: center;
        min-width: 40vw;
        margin: 100px 100px;
    }

    .work-card-action-btn{
        align-self: center;
        display: flex;
        flex-direction: row;
    }

    .change-btn{
        margin: 10px 5px;
        font-size: 35px;
        font-weight: 700;
    }
    



  }
  
  /* for Tablets and IPads Screen Viewports */
  @media (max-width: 1400px) {

    .work-section-flex-container{
        flex-direction: column;  
    }

    .work-nav-name-container{
        display: flex;
        flex-direction: column;
    }

    .company-name-button-list{
        display: flex;
        flex-direction: row;
        min-width: max-content;
    }

    .company-name-button-list{
        margin-top: 0px ;
    }

    .company-name-inner-layer{
        padding: 0.8rem;
        font-size: 16px;
     }

    
    .work-companies-list-flex-iteam{
        flex-direction: row;
    }

    .company-title{
        font-size: 12px;
        font-weight: bold;
        margin-top: 10px;
    }

    .rv-work-duty-tech-section{
        max-width: 100vw;
    }


    .techstack-container{
        margin: 5px 5px;
        max-width: 40vw;
     }

     /* Layout for card area */

    .work-card-section{
        display: flex;
        flex-direction: row; 
        justify-content: start;       
        max-width: 100vw;
    }

    .card-id-section{
        justify-content: start;
        align-self: center;
        margin-left: 10px;
        font-size: 60px;
        font-weight: bold;
        color: transparent;
        -webkit-text-stroke: 2px var(--white); /* Webkit/Blink browsers */
        text-stroke: 2px var(--white); /* Standard syntax */
        font-family: 'Orbitron', sans-serif;
    }

    .work-card-details{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    }

    .work-company-logo-card{
        /* justify-content: center; */
        align-self: end;
        margin: 5rem 14rem;

    }

    .work-card-action-btn{
        align-self: center;
        display: flex;
        flex-direction: row;
        
    }

    .change-btn{
        margin: 5px 5px;
        font-size: 25px;
        font-weight: 700;
    }

  }

    /* for landscape Tablets and IPads Screen Viewports */
    @media (max-width: 1100px) {
   
        .techstack-container{
            margin: 5px auto;
            max-width: 50vw;
         }

     /* Layout for card area */

    .work-card-section{
        display: flex;
        flex-direction: row; 
        justify-content: start;       
        max-width: 100vw;
    }

    .card-id-section{
        align-self: end;
        margin: 15px 10px;
        font-size: 50px;
    }

    .work-card-details{
        display: flex;
        flex-direction: column;
        max-width: 80vw;
    }

    .work-company-logo-card{
        justify-content: center;
        margin: 5rem 5rem;

    }

    .work-card-action-btn{
        align-self: center;
        display: flex;
        flex-direction: row;
    }

    .change-btn{
        margin: 5px 5px;
        font-size: 25px;
        font-weight: 900;
    }
      
    }


  /* for Mobile Screen Viewports */
  @media (max-width: 480px) {
    .work-company-logo-card{
        justify-content: center;
        margin: 100px auto;
        /* margin-left: 0px; */
        max-width: 90vw;
    }

    .work-section-flex-container{
        flex-direction: column;  
    }

    .work-nav-name-container{
        display: flex;
        flex-direction: column;
        max-width: 100vw;
    }

    .company-name-button-list{
        display: flex;
        flex-direction: row;
        min-width: max-content;
    }

    .company-name-button-list{
        margin-top: 0px ;
    }
    .company-name-nav-btns{
        margin: 5px;
        padding: 5px;
    }

    .company-name-inner-layer{
        min-width: max-content;
        padding: 0.8rem;
        font-size: 14px;
     }
    
    .work-companies-list-flex-iteam{
        flex-direction: row;
        margin: 5px;
        align-content: center;
        justify-content: left;
    }

    .company-title{
        font-size: 12px;
        font-weight: bold;
        margin-top: 10px;
    }

    .techstack-container{
       margin: 5px 5px;
       max-width: 99vw;
    }

     /* Layout for card area */

    .work-card-section{
        display: flex;
        flex-direction: column;        
        /* justify-content: center; */
    }

    .card-id-section{
        justify-content: center;
        align-self: start;
        margin: 5px;
        font-size: 50px;
        font-weight: bold;
        color: transparent;
        -webkit-text-stroke: 1px var(--white); /* Webkit/Blink browsers */
        text-stroke: 1px var(--white); /* Standard syntax */
    }

    .work-company-logo-card{
        justify-content: center;
        margin: 0px 10px;
        max-width: 95vw;
    }

    .work-card-details{
        display: flex;
        flex-direction: column;
    }

    .work-card-action-btn{
        align-self: center;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }

    .change-btn{
        margin: 15px 5px;
        font-size: 25px;
        font-weight: 700;
    }
  
  }