.tab-divider-style{
    border-top: 1px solid var(--primary-blue);
    border-bottom: 1px solid var(--primary-blue);
}

.flex-container{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
}

/* .carousel-container{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0px;
    z-index: 1;

}

.tab-carousel > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0px auto;
  }

.tab-carousel{

}

.tab-carousel-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-duration: 0ms;
  transform: translate3d(0px, 0px, 0px);
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;

}

.tab-carousel-btn{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    -moz-box-align: stretch;
    align-items: stretch;

    border: 0px;

    
    padding: 16px 12px;
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    
    overflow: visible;
    
    
} */


.carousel-container{
    width: 90%;
    max-width: 99vw;
    margin-left: auto;
    margin-right: auto;
    scrollbar-width: none;

    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0px;
    z-index: 1;

}


.tab-carousel-wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    position: relative;
}

.tab-carousel-btn{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    overflow: visible;
    cursor: pointer;

    padding: 16px 12px;
    white-space: nowrap;
    border: 0px;

    color: white;
    font-weight: 600;
}


::-webkit-scrollbar{
    height: 0;
    width: 0;
}

.is-active{
    color: var(--primary-blue);
    justify-content: center;
    align-items: center;
    font-weight: 700;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    transition-property: transform;
    /* transition-duration: 1s;
    transition-delay: 0.2s; */
}

  /* for Mobile Screen Viewports */
  @media (max-width: 480px) {
    .carousel-container{
        margin-left: 10px;
        margin-right: 5px;
    }
  }