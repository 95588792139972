.mkd-content-card{
    border:1px solid var(--primary-blue) ;
    /* background-origin: padding-box; */
    background-color: #101f3d;

    max-width: 90%;
    max-height: min-content;
    padding: 2px;
    margin: 10px 10px;

    position: relative;
}

.mkd-card-outer-layer {
    /* clip-path: polygon(45% 0%, 50% 10%, 95% 10%, 100% 15%, 100% 85%, 95% 100%, 50% 100%, 5% 100%, 0% 85%, 0% 0%); */

}

.mkd-card-inner-layer {
    /* clip-path: polygon(45% 0%, 50% 10%, 95% 10%, 100% 15%, 100% 85%, 95% 100%, 50% 100%, 5% 100%, 0% 85%, 0% 0%); */
}

/* .mkd-card-outer-layer{


}

.mkd-card-inner-layer{

} */
.mkd-card-title{
    margin: 10px;
    max-width: max-content;
    padding: 2px;
    
    clip-path: polygon(64.44% 0%, 82.22% 0%, 100% 0%, 100% 60.13%, 90% 100%, 30% 100%, 15.56% 100%, 0% 100%, 0% 0%);
    /* border-left: 5px solid var(--primary-blue); */
    border-right: 5px solid var(--primary-blue);

}

.mkd-header-outer-layer{
    clip-path: polygon(64.44% 0%, 82.22% 0%, 100% 0%, 100% 60.13%, 90% 100%, 30% 100%, 15.56% 100%, 0% 100%, 0% 0%);
    background-color: var(--primary-blue);
    max-width: max-content;
    max-height: max-content;
    padding: 1px;
    border: 2px solid var(--primary-blue);
}

.mkd-header-inner-layer{
    clip-path: polygon(80% 0%, 85% 10%, 100% 10%, 100% 60%, 90% 100%, 30% 100%, 15.56% 100%, 0% 100%, 0% 0%);
    background-color: #101f3d;



    font-size: 20px;
    font-weight: 800;
    padding: 5px;
    min-width: 10rem;

}


.mkd-card-content li{
    margin: 10px auto;
    font-size: 18px;
    max-width: 50rem;
}