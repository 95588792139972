.model-container-outer-layer, .model-container{
  clip-path: polygon(45% 0%, 52% 5%, 95% 5%, 100% 10%, 100% 95%, 90% 100%, 52% 100%, 10% 100%, 0% 95%, 0% 0%);
  max-width: 100vw;
  max-height: 100vh;
}


.main-container{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-container-outer-layer{

  background-color: var(--primary-blue);
  /* clip-path: polygon(45% 0%, 52% 8%, 95% 8%, 100% 15%, 100% 85%, 95% 92%, 90% 100%, 52% 100%, 10% 100%, 0% 85%, 0% 0%); */
  /* box-shadow: 24px; */
}
.model-container{
    color: var(--white);
    background-color: #101f3d;
    /* clip-path: polygon(45% 0%, 52% 8%, 95% 8%, 100% 15%, 100% 85%, 80% 85%, 60% 85%, 52% 100%, 10% 100%, 0% 85%, 0% 0%); */
    /* clip-path: polygon(45% 0%, 52% 8%, 95% 8%, 100% 15%, 100% 85%, 95% 92%, 90% 100%, 52% 100%, 10% 100%, 0% 85%, 0% 0%); */
}

.header-section{
    display: flex;
    flex-direction: column;
}
.header-section-tile-line{
  display: flex;
  flex-direction: row;
  position: relative;
}

.model-title-style::before{
  content: "#";
  display: inline-block;
  width: 0.8em;
}

.model-title-style::after{
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3.5em;
  height: 2px;
  margin-left: 0.5em;
  background-color: currentcolor;
}


.card-divider-style{
    margin: 1px auto;
    max-width: 80%;
    border-bottom: 2px solid var(--card-divider-color);
}

.card-description-styles{
    overflow-wrap: break-word;
    box-sizing: border-box;
    margin: 25px auto;
    padding-left: 10px;
    padding-right: 10px;
  }

.stack-name{
    font-weight: 500;
    color: #60b0cf;
    display: inline;
    margin: 5px auto;
}





/* MEDIA QUERIES */
/* for DeskTops and Laptops Screen Viewports */
@media (min-width: 1401px) {

    .stack-details-styles{
      font-size: 16px;
    }

    .model-container-outer-layer{
      padding: 2px;
      margin: 10px;
    }
  
    .model-title-style{
      font-size: 16px;
      font-weight: 500;
      color: #60b0cf;
      margin: 5px 1px;
      padding: 10px 15px;
    }

    .cards-title-style{
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      margin: 1px 20px;
    }

    .close-button{
      position: absolute;
      right: 25px;
      bottom: 0px;
      font-size: 22px; 
    }

    .card-description-styles{
        max-width: 30ch;
        font-size: 16px;
        /* font-weight: 400; */
        margin: 20px auto;
        text-align: left;
    }

    .stack-name{
        font-size: 16px;
        margin-bottom: 10px;    
    }

    .chips-styles{
        margin: 15px auto;
    }

    .tech-stack-style{
        margin: 15px;
    }


  }
  
  /* for Tablets and IPads Screen Viewports */
  @media (max-width: 1400px) {
  
    .stack-details-styles{
      font-size: 14px;
      margin-top: 5px;
    }

    .model-container-outer-layer{
      padding: 2px;
      margin: 10px;
    }
  
    .model-title-style{
      font-size: 16px;
      font-weight: 500;
      color: #60b0cf;
      margin: 1px 2px;
      padding: 10px 15px;
    }

    .cards-title-style{
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      margin: 1px 20px;
    }

    .close-button{
      position: absolute;
      right: 25px;
      bottom: 0px;
      font-size: 22px; 
    }


    .card-description-styles{
        max-width: 30ch;
        font-size: 16px;
        margin: 20px auto;
        text-align: left;
    }
  
    .tech-stack-style{
        margin: 15px;
    }



    .chips-styles{
        margin: 15px auto;
    }

    .stack-name{
        font-size: 16px;
        margin-bottom: 10px;    
    }
  }
  
  /* for Mobile Screen Viewports */
  @media (max-width: 480px) {

    .stack-details-styles{
      font-size: 12px;
      margin-top: 5px;
    }

    .model-container-outer-layer{
      padding: 2px;
      margin: 10px;
    }
  
    .model-title-style{
      font-size: 16px;
      font-weight: 500;
      color: #60b0cf;
      margin: 5px 1px;
      padding: 10px 15px;
    }

    .cards-title-style{
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      margin: 1px 20px;
    }

    .close-button{
      position: absolute;
      right: 25px;
      bottom: 0px;
      font-size: 22px; 
    }

    .card-description-styles{
        max-width: 30ch;
        font-size: 18px;
        font-weight: 400;
        margin: 15px auto;
        text-align: left;
    }
  
    .tech-stack-style{
        margin: 25px;
    }

    .chips-styles{
        margin: 10px auto;
    }

    .stack-name{
        font-size: 16px;
        margin-bottom: 10px;

    }

  
  
  }