:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --logo-blue: #599fce;
  --primary-blue: #60b0cf;
  --black: #1f1f1f;
  --background-dark-blue: #23345c;

  --chips-colors: #7a95b7;
  --card-divider-color: #7a95b7;

  /* Fonts */
  --primary-font-family: ;
  --secondary-font-family: ;
  --desktop-font-size: ;
  --ipad-font-size: ;
  --mobile-font-size: ;

  scrollbar-color: var(--background-dark-blue) var(--black);
  scrollbar-width: thin;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to top,#0b111f 0, #23345c 100%); */
  background-image: linear-gradient(to right top, #0b111f, #111a2d, #16233c, #1c2b4c, #23345c);
  padding: 15px;
  scroll-behavior: smooth;
  scrollbar-color: var(--primary-blue) var(--black);
  scrollbar-color: inherit;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
  /* height: 15px; */
}

::-webkit-scrollbar-track  {
  background-color: var(--black);
  width: 8px;

}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 10px;
  background-color: var(--background-dark-blue);
}

/* ::-webkit-scrollbar{
  width: 5px;
  background: black;
}

::-webkit-scrollbar-track{
  background: black;
}

::-webkit-scrollbar-thumb{
  background: red;
} */

/* ::-webkit-scrollbar-thumb {
  background: blue;
  border-radius: 5px;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
} */


/* 
Font Size for Mobile: 

Heading fonts: 40px 
Section headings fonts: 22px 18px
Text fonts: 14

card label, details, unimportant: 10px
card Heading 1: 16px
card Heading 2: 14px
card text: 12px

Tab headings: 10px
Content headings: 14px

button text: 10px or 12px

icon logos: 16px


*/

/* Desktop And Higher screen size */
/* 

Heading fonts: 40px 
Section headings fonts: 18px
Text fonts: 14

card label, details, 
unimportant: 12px
card Heading 1: 16px
card Heading 2: 14px
card text: 12px

Tab headings: 10px
Content headings: 14px

button text: 10px or 12px

icon logos: 16px



 */

/* for Mobile Screen Viewport */
@media (max-width: 480px) {
  :root {
    /* Greeting section */
    --greeting-heading: 40px;
    --greeting-text: 18px

    /* Social buttons */
  }


  :root{
    max-width: 100vw;
    margin: 0;
  }

  ::-webkit-scrollbar-track  {
    background-color: var(--black);
    width: 1px;
  }

}
/* for Tablets and IPads Screen Viewport */
@media (max-width: 1100px) {
  :root {
  /* logo Section */
   --logo-font-size: 0px;
   --logo-line-height: 12px;

  /*  Nav Item Sections */
  --nav-items-font-size: 20px;

  /* Greeting section */
  --greeting-heading: 61px;
  --greeting-text: 25px

  /* social icons */


  }
}

/* for DeskTops and Laptops Screen Viewport */
  @media (max-width: 1800px) {
    :root {
    /* Greeting section */
    --greeting-heading: 61px;
    --greeting-text: 25px


    }


 }



 