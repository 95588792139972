.container{
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* height: 100px; */
}

.line1, .line2, .line3{
    /* width: 50px; */
    height: 0px;
    border: 2.3px dashed #757b9e;
}


.line1{
    align-self: end;
    /* margin-right: -25px; */
}

.line2{
    margin: 0px;
    /* width: 100px; */
    align-self: center;
    transform: rotate(-60deg);
    
}

.line3{
    align-self: start;
    /* margin-left: -25px; */
    
}

.bottom-end-icon{
    align-self: end;
    font-size: 18px;
    color: #757b9e;
    margin-bottom: -11px ;
}

.top-start-icon{
    /* align-self: start; */
    font-size: 18px;
    color: #757b9e;
    margin-top: -7px ;
    display: inline-flex;
    /* margin-bottom: -11px ; */
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.child-style{
    align-self: start;
}



/* MEDIA QUERIES */
/* for Desktops and Laptops Screen Viewport */
@media (min-width: 1401px) {
    .line1, .line2, .line3{
        border: 2.3px dashed #757b9e;
    }
}

/* for Tablets and IPads Screen Viewport */
@media (max-width: 1400px) {
    .line1, .line2, .line3{
        border: 2.3px dashed #757b9e;
    }
}



/* for Tablets and IPads Screen Viewport */
@media (max-width: 1100px) {
    .line1, .line2, .line3{
        border: 2.3px dashed #757b9e;
    }

}



/* for Mobile Screen Viewport */
@media (max-width: 480px) {
    .line1, .line2, .line3{
        border: 1.9px dashed #757b9e;
        height: 0px; 
    }

    .child-style{
        /* margin-top: -5px; */
    }

}