.education-container-0, .education-container{
    max-width: 500px;
    clip-path: polygon(45% 0%, 52% 8%, 95% 8%, 100% 15%, 100% 85%, 95% 92%, 90% 100%, 52% 100%, 10% 100%, 0% 85%, 0% 0%);
}


.education-container-0{
    background-color: var(--primary-blue);
    padding: 2px;
  }

.education-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #101f3d;
    padding: 10px;
}

.image-layer-outer, .image-layer-inner {
    /* clip-path: polygon(45% 0%, 52% 8%, 95% 8%, 100% 15%, 100% 85%, 95% 92%, 90% 100%, 52% 100%, 10% 100%, 0% 85%, 0% 0%); */
    clip-path: polygon(70% 0%, 100% 15%, 100% 90%, 90% 100%, 30% 100%, 0% 85%, 0% 0%);
}

.image-layer-outer{
    max-height: 120px;
    margin-top: -5px;
    background-color: var(--primary-blue);
    padding: 1px;
}

.image-layer-inner{
    max-height: 120px;
    background-color: #101f3d;

}

.image-layer-inner img {
    filter: grayscale(100%);
    transition:  0.5s ease;
    cursor: pointer;
}

.image-layer-inner img:hover {
    transform: scale(1.3);
    filter: grayscale(0%);
}


.education-details{
    margin-left: 10px;
}

.desgree-type-styles{
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold;
    color: #60b0cf;
    text-transform: uppercase;
    text-align: left;


}

.school-name{
    justify-content: start;
    font-size: 25px;
    margin-top: 2px;
}

.degree-title{
    font-size: 14px;
    margin-top: 15px;

    font-weight: bold;
    color: #60b0cf;
    text-align: left;
    text-transform: uppercase;
}

.major-style{
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
    text-transform: uppercase;
}

.graducation-style{
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
    color: #757b9e;
    text-transform: uppercase;


}

/* MEDIA QURIES */
/* for Mobile Screen Viewports */
@media (max-width: 480px) {

    .education-container{
        max-width: 600px;
        padding: 10px;
    }

    .desgree-type-styles{
        font-size: 11px;
        margin-top: 5px;
    }

    .school-name{
        font-size: 20px;
        margin-top: 1px;
        font-weight: 500;
    }

    .degree-title{
        font-size: 11px;
        margin-top: 10px;
    }

    .major-style{
        font-size: 12px;
        margin-top: 5px;
        font-weight: 500;
    }

    .graducation-style{
        font-size: 12px;
        margin-top: 5px;
    }

}