.skills-section-container{

}

.section-side-title{
    transform: rotate(-90deg) translate(-20%, -200%);
    transform-origin: left top 0px;
    white-space: nowrap;
    color: var(--primary-blue);

    /* color: rgb(28, 29, 42);  */
    font-size: 0.865rem;
    letter-spacing: 0.25em;
    font-weight: bold;

}

.section-side-title::after{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 2.5em;
    height: 1px;
    margin-left: 0.5em;
    background-color: currentcolor;
}


.skills-stack-flex-container{
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    flex-wrap: wrap;
    /* width: 100% auto; */
    /* justify-self: center; */
}

.skills-stack-flex-items{
    width: 350px;
    margin: 15px;
    border-left: 5px solid #7a95b7;
}

.icon-style{
    margin-right: 10px;
}

.social-flex-container{
    display: flex;

    flex-wrap: wrap;

    font-size: 15px;
}
/* New skills section */

.group-button-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
.group-button-flex-item{

}

.skills-card-flex-item{
    min-height: 70vh;
    margin: 15px;
    padding: 5px;
    text-align: center;
    
    /* max-width: 60vw; */
    border-color: var(--primary-blue);
    border-style: solid;
    border-width: 1px;
    background-color: #101f3d;
    padding: 15px;
}


/* MEDIA QUERIES */
/* for DeskTops and Laptops Screen Viewports */
@media (min-width: 1101px) {
    .social-flex-container{
        flex-direction: column;
        align-self: center;
        /* justify-content: start; */
    }

    .group-button-flex-item{
        margin: 15px;
        text-align: center;
    }
    .skills-section-container{
        margin-bottom: 10vh;
    }

    .skills-card-flex-item{
        min-height: 50vh;
        max-width: 60vw;
        margin: 15px;
        padding: 5px;
        justify-content: center;
        align-self: center;
        /* text-align: center; */
    
    }
    
}

/* for Tablets and IPads Screen Viewports */
@media (max-width: 1200px) {
    .social-flex-container{
        flex-direction: column;
        align-self: center;
        justify-content: start;
    }

    .group-button-flex-item{
        margin: 15px;
        margin-top: 30px;
        text-align: center;
    }

    .skills-section-container{
        margin-bottom: 8vh;
    }
    .skills-card-flex-item{
        min-height: 60vh;
        max-width: 80vw;
        margin: 15px;
        padding: 5px;
        justify-content: center;
        /* text-align: center; */
    
    }
    
}

/* for Mobile Screen Viewports */
@media (max-width: 480px) {
    .skills-section-container{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .social-flex-container{
        flex-direction: row;
        align-self: center;
        justify-content: center;
    }

    .group-button-flex-item{
        margin: 15px;
        text-align: center;
    }

    .skills-card-flex-item{
        min-height: 70vh;
        max-width: 90%;
        margin-top: 15px;
        margin-left: 50px;
        margin-right: 15px;
        padding: 5px;
    
    }
}