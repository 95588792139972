.nav_container{
    margin: 1 auto;
    display: flex;
    justify-content: center;
    width: 100vw;
    top:0;
    position: fixed;
    transition-timing-function: ease-in;
    transition: 0.5s;
    z-index: 1000;

    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
}

.show-navbar{
    visibility: visible;
    transition: all 200ms ease-in;
}

.hide-navbar{
    visibility: hidden;
    transition: all 200ms ease-out;
    transform: translate(0, -100%);
}


nav{
    position: relative;
    width: 100%;
    height: 50px;
    padding-top: 0 ;
    margin-top: 0;
    top:0;

}

.nav_list{
    list-style-type: none;
    background: inherit;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.nav_item{
    margin-right: 20px;
    text-decoration: none;

    text-transform: uppercase;
    color: rgb(255, 255, 255);
    cursor: pointer;
    padding-bottom: 20px;
}

.nav_item:hover{
    color: var(--primary-blue);
}


/* MEDIA QUERIES */
/* for DeskTops and Laptops Screen Viewports */
@media (min-width: 1101px) {
    .nav_item{
        font-size: 20px;
    }
}

/* for Tablets and IPads Screen Viewports */
@media (max-width: 1100px) {
    .nav_item{
        font-size: 18px;
    }
}