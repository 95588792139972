/* Common styles */

.flex-center-colum{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-center-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.flex-colum{
    display: flex;
    flex-direction: column;
}

.flex-row{
    display: flex;
    flex-direction: row;
}



.isHidden{
    display: none;
}

.isActive{
   background-color: #757b9e;
}

/* End of shared styles */

.rv-work-card-container{
   /* display: flex;
   flex-direction: row; */
}

.rv-work-company-details-section{

}

.rv-work-duty-tech-section{

}

.rv-company-info{
    position: relative;
    z-index: 2;

    max-width: 100%;
    max-height: 100%;
}

.rv-company-image{
    
}

/* .rv-company-info,  */
/* .rv-company-image{
    display: flex;
    justify-content: center;
} */


/* Location */
/* TODO: Change later  */
.rv-location-container{
   position: absolute;
   top: -5px;
   right: -180px;
}

.location-container{
    max-width: max-content;
    margin-top: -18px;
    margin-left: 5px;
    border-left: 5px solid var(--primary-blue);
    padding-left: 10px;
}


.location-style{
    max-width: max-content;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    color: var(--white);
    text-transform: uppercase;
}

/* Company Name */
/* TODO: remove later */
.company-name-container{
    position: absolute;
    bottom: 12%;
    right: 20%;
    padding: 15px;
    min-width: 21rem;
    border: 2px solid var(--primary-blue);
    background-color: #101f3d;
    z-index: 3;
}

.rv-image-layer-outer, .rv-image-layer-inner {
    /* clip-path: polygon(45% 0%, 52% 8%, 95% 8%, 100% 15%, 100% 85%, 95% 92%, 90% 100%, 52% 100%, 10% 100%, 0% 85%, 0% 0%); */
    clip-path: polygon(12.5% 0%, 48% 0%, 52.96% 11.25%, 92.38% 11.25%, 100% 21.87%, 100% 82.25%, 100% 82.25%, 89.42% 100%, 60.06% 100%, 52.96% 89.5%, 12.5% 89.5%, 0% 74.25%, 0% 11.25%);
    max-width: 100%;
    max-height: 100%;
}

.rv-image-layer-outer{
    background-color: var(--primary-blue);
    padding: 3px;
}

.rv-image-layer-inner{
    clip-path: polygon(7% 5%, 50% 5%, 52.96% 11.25%, 92.38% 11.25%, 100% 21.87%, 100% 82.25%, 100% 82.25%, 92% 94%, 58% 94%, 54% 88%, 12% 88%, 0% 74.25%, 0% 11.25%);
    background-color: #101f3d;

}

.rv-image-layer-inner img {
    /* filter: grayscale(100%);
    transition:  0.5s ease; */
    cursor: pointer;
    object-fit:contain;
}

.rv-image-layer-inner img:hover {
    /* transform: scale(1.3);
    filter: grayscale(0%); */
}


.company-role-item, .rv-company-details{
    display: flex;
    flex-direction: row;
    justify-content: center;
}


/* Title and location */

.rv-title-container{
    position: absolute;
    bottom: -45px;
    right: -350px;
}

.role-tile-container{
    margin-top: -15px;
    margin-left: 5px;
    border-left: 5px solid var(--primary-blue);
    padding-left: 10px;
}

.role-title-style{
    text-align: left;
    text-transform: uppercase;
    color: var(--white);
}

/* roles container div */

/* .rv-work-roles{
   display: flex;
   flex-direction: row;
} */

.role-button-div{
    border: 2px solid var(--primary-blue);
    border-radius: 2px;
    margin: 8px;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;

}

/* role titles */

.org-title, .role-title{
    color: var(--primary-blue);
    text-transform: uppercase;
    text-align: left;
}


/* start date && End date */

/* flotting postions*/

.rv-startDate-container{
    position: absolute;
    z-index: 3;
    top: -30px;
    right: 250px;
 }
 
 .rv-endDate-container{
    position: absolute;
    z-index: 3;
    bottom: 10px;
    right: 250px;
 }


.start-date-left-container{
    margin-top: -30px;
    margin-right: 5px;
    border-right: 5px solid var(--primary-blue);
    padding-right: 10px;
}

.end-date-left-container{
    margin-top: 25px;
    margin-right: 5px;
    border-right: 5px solid var(--primary-blue);
    padding-right: 10px;
    align-self: flex-end;
}

.date-title {
    color: #757b9e;
    text-transform: uppercase;
    text-align: left;
}


/* Desktop */
.date-title{
    font-size: 14px;
    font-weight: bold;
    margin-top: 0px;
}



/* TechTabs and Responsibility  */

.rv-work-duty-tech-section{
    display: flex;
    flex-direction: row;
}


/* Toggle section */

.toggle-btn-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-self: end;  */


}

.toggle-btn{
    cursor: pointer;
    border: 2px solid var(--primary-blue);
    
    font-size: 14px;
    font-weight: 800;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;

}



/* MEDIA QUERIES */
/* for Desktops and Laptops Screen Viewport */
@media (min-width: 1401px) {

    .rv-work-company-details-section{
        margin: 30px auto;
    }

    .rv-company-info{
        margin-bottom: 5rem;
    }


    .start-date-left-container{
        min-width: max-content;
    }
    
    .end-date-left-container{
        min-width: max-content;
    }
    

    .role-title{
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
    }

    .role-title-style{
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
    }

    .start-date-left-container{
        border-right: 5px solid var(--primary-blue);
    }
    
    .end-date-left-container{
        border-right: 5px solid var(--primary-blue);
    }


    /* section positions */

    .rv-startDate-container{
        position: absolute;
        z-index: 3;
        top: -30px;
        right: 250px;
     }
     
     .rv-endDate-container{
        position: absolute;
        z-index: 3;
        bottom: 10px;
        right: 250px;
     }

     .rv-location-container{
        position: absolute;
        top: -5px;
        right: -180px;
     }

     .rv-title-container{
        position: absolute;
        bottom: -45px;
        right: -260px;
    }

    /* role section */
    .rv-work-roles{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        padding-left: 10px;
        /* border-left: 4px solid var(--primary-blue); */
     }
     
     .role-button-div{
         border: 2px solid var(--primary-blue);
         border-radius: 2px;
         margin-right: 8px;
         padding: 5px;
         font-size: 16px;
         font-weight: 600;
         text-transform: uppercase;
     }
}


/* for Tablets and IPads Screen Viewport */
@media (max-width: 1400px) {

    .rv-work-company-details-section{
        margin: 25px auto;
    }

    .rv-company-info{
        margin-bottom: 5rem;
    }


    .role-title-style{
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
        line-break: auto;
    }

    .location-style{
        font-size: 14px;
        margin-top: 10px;
    }

    .date-title, .role-title{
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
    }
     
    .start-date-left-container{
        border-right: 5px solid var(--primary-blue);
    }
    
    .end-date-left-container{
        border-right: 5px solid var(--primary-blue);
    }


    /* section position */
    .rv-startDate-container{
        position: absolute;
        z-index: 3;
        top: -30px;
        right: 250px;
        min-width: max-content;
     }
     
     .rv-endDate-container{
        position: absolute;
        z-index: 3;
        bottom: 10px;
        right: 250px;
        min-width: max-content;
     }

     .rv-location-container{
        position: absolute;
        top: -5px;
        right: -180px;
     }

     .rv-title-container{
        position: absolute;
        bottom: -45px;
        right: -300px;
    }

    /* role section */
    .rv-work-roles{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-self: start;
     }
     
     .role-button-div{
         border: 2px solid var(--primary-blue);
         border-radius: 2px;
         margin-right: 8px;
         padding: 5px;
         font-size: 16px;
         font-weight: 600;
         text-transform: uppercase;
     }

}

/* for Tablets and IPads Screen Viewport */
@media (max-width: 1100px) {
    /* section text */

    .role-title-style, .location-style{
        font-size: 13px;
        margin-top: 5px;
        line-break: auto;
    }

    .date-title, .role-title{
        font-size: 13px;
        font-weight: bold;
        margin-top: 5px;
    }

    .start-date-left-container{
        border-right: 5px solid var(--primary-blue);
    }
    
    .end-date-left-container{
        border-right: 5px solid var(--primary-blue);
    }

    
    /* section positions */

    .rv-startDate-container{
        position: absolute;
        z-index: 3;
        top: -35px;
        right: 195px;
        min-width: max-content;
     }
     
     .rv-endDate-container{
        position: absolute;
        z-index: 3;
        bottom: 10px;
        right: 200px;
        min-width: max-content;
     }

     .rv-location-container{
        position: absolute;
        top: -20px;
        right: -180px;
     }

     .rv-title-container{
        position: absolute;
        bottom: -25px;
        right: -220px;
    }


}


/* for LAndscape MObile Screen Viewport */
@media (max-width: 900px) {

    .rv-title-container{
        /* display: none; */
    }
      
}



/* for Mobile Screen Viewport */
@media (max-width: 480px) {
    
    .role-title-style, .location-style{
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
        max-width: 160px;
    }

    .date-title, .role-title{
        font-size: 12px;
        font-weight: bold;
        margin-top: 2px;
    }

    .start-date-left-container{
        border-right: 4px solid var(--primary-blue);
    }
    
    .end-date-left-container{
        border-right: 4px solid var(--primary-blue);
    }

    .rv-work-company-details-section{
        margin: 105px auto;
    }

    .rv-company-info{
        margin-bottom: 10rem;
    }

    /* section positions */

    .rv-startDate-container{
        top: 150px;
        right: 70px;
        min-width: max-content;
     }
     
     .rv-endDate-container{
        top: 150px;
        right: 20px;
        min-width: max-content;
     }

     .rv-location-container{
        position: absolute;

        top: -80px;
        right: -20px;
        min-width: 260px;
     }

     .rv-title-container{
        /* TODO: once the new connector are created display title at its new location top above location */
        /* display: none;  */
        position: absolute;

        top: -20px;
        right: -80px;

        min-width: 175px;
    }

    /* role section */
    .rv-work-roles{
        display: flex;
        flex-direction: row;
        align-self: start;
        margin-top: 10px;
     }
     
     .role-button-div{
         /* border: 2px solid var(--primary-blue); */
         /* border-radius: 5px; */
         margin-right: 8px;
         padding: 5px;
         font-size: 12px;
         font-weight: 600;
         text-transform: uppercase;
     }

    

}