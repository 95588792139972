.background{
    /* background: linear-gradient(to top,#0b111f 0, #23345c 100%); */
    /* background: linear-gradient(to top,#0b111f 0, #23345c 100%); */
    width: 100%;
    height: inherit;
    color: hsl(0, 0%, 100%);
    font: 'Monospace';
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: rgb(28, 24, 24);
  } */

/* ::-webkit-scrollbar{
    width: 5px;
    background: black;
  }

  ::-webkit-scrollbar-track{
    background: black;
  }

  ::-webkit-scrollbar-thumb{
    background: red;
  } */


  .scroller {
    overflow-y: scroll;
    scrollbar-color: #0A4C95 #C2D2E4;
  }
  
  .scroller::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }
  
  .scroller::-webkit-scrollbar-track-piece  {
    background-color: #C2D2E4;
  }
  
  .scroller::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: #0A4C95;
  }

/* MEDIA QUERIES */
/* for DeskTops and Laptops Screen Viewports */
@media (min-width: 1101px) {
    .hero-page-section{
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .page-section-container {
        margin-left: 10%;
        margin-right: 10%;
    }
}

/* for Tablets and IPads Screen Viewports */
@media (max-width: 1100px) {
    .hero-page-section{
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page-section-container {
        margin-left: 10%;
        margin-right: 10%;
    }
}

/* for Mobile Screen Viewports */
@media (max-width: 480px) {
  .background{
    max-width: 100vw;
  }
   
    .page-section-container {
        margin-left: 0px;
        margin-right: 0px;
    }

    .hero-page-section{
        height: 90vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;

    }

    .mobile-logo-style{
        margin: 10px auto;
        justify-content: start;

    }
    
    .scroller::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

}